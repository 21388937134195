import './HallAppContainer.scss';

function HallAppContainer({ children, style }) {
    return (
        <div className='container'>
            <div style={style} className='container-hallapp-wrapper'>
                <div className='container container-hallapp'>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default HallAppContainer;