import './Companies.scss'
import 'react-swipeable-list/dist/styles.css';

import { Col, Row } from "react-bootstrap"
import { LeadingActions, SwipeAction, SwipeableList, SwipeableListItem, TrailingActions } from "react-swipeable-list"
import { faAdd, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"

import Alert from 'react-bootstrap/Alert';
import AppLoading from "../../commons/AppLoading"
import CompanyAPI from './CompanyAPI';
import ConfirmModal from "../../commons/modal/ConfirmModal"
import CustomCard from "../../commons/cards/Card"
import FloatingButton from "../../commons/buttons/FloatingButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import HallAppConst from "../../commons/util/HallAppConst"
import HallAppContainer from "../../commons/containers/HallAppContainer"
import HallAppToast from "../../commons/HallAppToast"
import ModalCompanyEditForm from './components/ModalCompanyEditForm';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { fetchCompanies } from "../../redux/actions/companyAction"

function Companies() {
    const [isLoading, setIsLoading] = useState(true)
    const [showMoadlEdit, setShowMoadlEdit] = useState(false)
    const [company, setCompany] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [isLoadingModalDelete, setIsLoadingModalDelete] = useState(false)

    const service = new CompanyAPI()
    const dispatch = useDispatch();
    const companies = useSelector(state => state.company.companies);

    useEffect(() => {
        callGetEstablishments()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const callGetEstablishments = async () => {
        setIsLoading(true)
        dispatch(fetchCompanies());
        setIsLoading(false)
    }

    const deleteEstablishments = async () => {
        setIsLoadingModalDelete(true)
        await service.deleteEstablishments(company.id)
            .then(async (_) => {
                HallAppToast.success();
                setShowModalDelete(false)
                await callGetEstablishments();
            }).catch((_) => {
                HallAppToast.error(HallAppConst.GENERIC_ERROR);
            }).finally((_) => {
                setIsLoadingModalDelete(false)
            })

    }

    const editAction = (company) => (
        <LeadingActions>
            <SwipeAction onClick={() => {
                setCompany(company)
                setShowMoadlEdit(true);
            }}>
                <div className="swipe-container-company">
                    <div className="swipe-edit-company">
                        <FontAwesomeIcon icon={faEdit} size="lg" />
                        <div>EDITAR</div>
                    </div>
                </div>
            </SwipeAction>
        </LeadingActions>
    );

    const deleteAction = (company) => (
        <TrailingActions>
            <SwipeAction
                destructive={false}
                onClick={() => {
                    setCompany(company)
                    setShowModalDelete(true);
                }}
            >
                <div className="swipe-container-company">
                    <div className="swipe-delete-company">
                        <FontAwesomeIcon icon={faTrash} size="lg" />
                        <div>EXCLUIR</div>
                    </div>
                </div>
            </SwipeAction>
        </TrailingActions>
    );


    return (
        <>
            <PullToRefresh onRefresh={callGetEstablishments}>
                <div className='container'>
                    <Row style={{ margin: 0, width: '100%'}}>
                        {isLoading ? (
                            <Col xs={12}>
                                <HallAppContainer>
                                    <AppLoading />
                                </HallAppContainer>
                            </Col>
                        ) : (
                            <>

                                <h4>Seus Estabelecimentos</h4>
                                <hr></hr>
                                <Alert key={'info'} variant={'info'}>
                                    Para excluir um estabeleicmento, arraste o card para o lado <span style={{ fontWeight: 'bold' }}>Direito </span>
                                    e para editar arraste para o lado <span style={{ fontWeight: 'bold' }}>Esquerdo.</span>
                                </Alert>
                                {
                                    companies.map(data => (
                                        <Col xs={12} sm={12} md={4} lg={4} key={data.id} style={{ marginBottom: '10px' }}>
                                            <SwipeableList style={{ width: '100%' }}>
                                                <SwipeableListItem
                                                    leadingActions={editAction(data)}
                                                    trailingActions={deleteAction(data)}
                                                    key={data.id}
                                                    fullSwipe={false}
                                                >
                                                    <div style={{ width: '100%' }}>
                                                        <CustomCard minHeight={'170px'}>
                                                            <p className="hallapp-subtitle" style={{ fontWeight: 'bold' }}>
                                                                {data.unit != null ? data.unit : data.name}
                                                            </p>
                                                            <p>{data.name}</p>
                                                            <p>{data.street}, {data.number} - {data.city}/{data.state}</p>
                                                        </CustomCard>
                                                    </div>
                                                </SwipeableListItem>
                                            </SwipeableList>
                                        </Col>

                                    ))
                                }
                            </>
                        )}
                    </Row>
                </div>
            </PullToRefresh>

            <FloatingButton icon={faAdd} onClick={() => {
                setShowMoadlEdit(true);
                setCompany(null);
            }} />

            <ModalCompanyEditForm
                hasUpdated={() => callGetEstablishments(true)}
                show={showMoadlEdit}
                closeShow={() => setShowMoadlEdit(false)}
                company={company} />
            <ConfirmModal show={showModalDelete}
                title='Excluir'
                isLoading={isLoadingModalDelete}
                confirm={() => deleteEstablishments()}
                closeModal={() => setShowModalDelete(false)}
            >
                Deseja remover o estabelecimento <span style={{ fontWeight: 'bold' }}>{company?.name}</span>?
            </ConfirmModal>
        </>

    );
}

export default Companies;