import '../Services.scss'

import * as formik from 'formik';
import * as yup from 'yup';

import { Alert, Button, ButtonGroup, Form } from "react-bootstrap";
import { compressImage, displayName } from '../../../commons/util/HallAppUtil';
import { useEffect, useState } from "react";

import AppInput from "../../../commons/inputs/AppInput";
import AppLoading from "../../../commons/AppLoading";
import CompanyAPI from "../../company/CompanyAPI";
import HallAppCenterContainer from "../../../commons/containers/HallAppCenterContainer";
import HallAppConst from "../../../commons/util/HallAppConst";
import HallAppToast from "../../../commons/HallAppToast";
import ServiceAPI from "../ServiceAPI";

function AddEmployee({ service, backStep, nextStep, serviceToEdit }) {

    const [isLoading, setIsLoading] = useState(true)
    const [employees, setEmployees] = useState([])

    const [initialValues, setInitialValues] = useState({
        ...employees.reduce((acc, e) => {
            acc[`percentage_${e.id}`] = '';
            acc[`checkbox_${e.id}`] = false;
            return acc;
        }, {})
    });

    const { Formik } = formik;


    const schema = yup.object().shape({
        ...employees.reduce((acc, e) => {
            acc[`percentage_${e.id}`] = yup
                .number()
                .min(0, 'Valor não pode ser menor que 0')
                .max(100, 'Valor não pode ser maior que 100');
            return acc;
        }, {})
    });

    const companyAPI = new CompanyAPI();
    const serviceAPI = new ServiceAPI();

    useEffect(() => {
        getAllEmployee();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (serviceToEdit != null && Object.keys(serviceToEdit).length > 0) {
            const { employees } = serviceToEdit;
            const newValues = {
                ...initialValues,
                ...employees.reduce((acc, e) => {
                    acc[`percentage_${e.employeeId}`] = e.percentage || '';
                    acc[`checkbox_${e.employeeId}`] = e.percentage >= 0; // Marca o checkbox se a porcentagem for maior que 0
                    return acc;
                }, {})
            };
            setInitialValues(newValues);
            // eslint-disable-next-line react-hooks/exhaustive-deps       
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceToEdit]);

    const getAllEmployee = async () => {
        setIsLoading(true)
        try {
            const response = await companyAPI.getAllEmployee(companyAPI.getCompanyManagment().id);
            setEmployees(response.data)
            setIsLoading(false)
        } catch (_) {
            setEmployees([])
            setIsLoading(false)
        }
    };

    const createOrUpdate = async (values) => {
        setIsLoading(true)

        try {
            const selectedEmployes = [];

            employees.forEach((e) => {
                const percentageEmployee = `percentage_${e.id}`;
                const checked = values[`checkbox_${e.id}`];
                if (checked) {
                    const [, num] = percentageEmployee.split("_");
                    const data = {
                        employeeId: num,
                        percentage: values[`percentage_${num}`]
                    }
                    selectedEmployes.push(data)
                }
            })

            const data = {
                ...service,
                employees: selectedEmployes,
                companyId: companyAPI.getCompanyManagment().id
            }

            const formData = new FormData();
            formData.append("dto", new Blob([JSON.stringify(data)], { type: "application/json" }));

            if (service.img !== null && service.img !== undefined) {
                const logoCompress = await compressImage(service.img);
                formData.append("imgService", logoCompress);
            }

            if (serviceToEdit != null && Object.keys(serviceToEdit).length > 0) {
                await serviceAPI.update(serviceToEdit.id, formData)
            } else {
                await serviceAPI.create(formData)
            }

            setIsLoading(false)
            HallAppToast.success();
            if (nextStep) nextStep(data)
        } catch (_) {
            setIsLoading(false)
            HallAppToast.error(HallAppConst.GENERIC_ERROR);
        }
    }

    return (
        <>
            <Alert key="info" variant="info">
                Este campo de porcentagem define o percentual que será aplicado nos ganhos do funcionário, baseado nas comandas abertas. Por favor, insira o valor correspondente para o cálculo correto dos seus ganhos.
                O campo é opcional.
            </Alert>

            <h5 style={{ textAlign: 'center' }}>Selecione os funcionarios que executam esse serviço.</h5>
            <hr></hr>
            <Formik
                validationSchema={schema}
                onSubmit={createOrUpdate}
                enableReinitialize={true}
                initialValues={initialValues}
            >
                {({ handleSubmit, handleChange, values, touched, errors, setFieldValue, resetForm }) => (
                    <div className='container'>
                        <Form noValidate onSubmit={handleSubmit}>

                            <div style={{ marginBottom: '40px' }}>
                                {employees.map((e) => {
                                    const handleCheckboxChange = (event) => {
                                        const isChecked = event.target.checked;
                                        const inputName = `percentage_${e.id}`;

                                        // Atualiza o estado do checkbox no Formik
                                        setFieldValue(`checkbox_${e.id}`, isChecked);

                                        // Reseta o valor do input
                                        if (!isChecked) {
                                            setFieldValue(inputName, ''); // Reseta o input se o checkbox for desmarcado
                                        }
                                    };

                                    return (
                                        <div className={!values[`checkbox_${e.id}`] ? '' : 'service-employee-checked'} style={{
                                            padding: '6px', marginTop: '6px', borderRadius: '8px'
                                        }}>
                                            <label className="list-group-item">
                                                <input
                                                    className="form-check-input me-1"
                                                    type="checkbox"
                                                    onChange={handleCheckboxChange}
                                                    checked={values[`checkbox_${e.id}`]} // Atualiza o estado do checkbox
                                                />
                                                <span style={{ color: !values[`checkbox_${e.id}`] ? '' : '#fff' }}>{displayName(e?.name) || e?.email}</span>
                                                <AppInput
                                                    errors={errors}
                                                    touched={touched}
                                                    name={`percentage_${e.id}`}
                                                    onChange={handleChange}
                                                    value={values[`percentage_${e.id}`]}
                                                    type="number"
                                                    disabled={!values[`checkbox_${e.id}`]}
                                                    label="Porcentagem"
                                                />
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>

                            <div style={{
                                position: 'fixed',
                                bottom: 0,
                                left: 0,
                                border: 0,
                                borderRadius: 0,
                                width: '100%',
                            }}>

                                {
                                    isLoading ? <HallAppCenterContainer><AppLoading /></HallAppCenterContainer> : <ButtonGroup style={{ borderRadius: '0', width: '100%', }}>
                                        <Button onClick={backStep} variant="outline-dark" size="lg" style={{ borderRadius: '0', width: '100%', }}>
                                            VOLTAR
                                        </Button>
                                        <Button className="btn-next-category" type="submit" variant="dark" size="lg" style={{ borderRadius: '0', width: '100%', color: '#fff' }}>
                                            <span style={{ color: '#fff' }}>SALVAR</span>
                                        </Button>
                                    </ButtonGroup>
                                }

                            </div>

                        </Form>
                    </div>
                )}
            </Formik>


        </>
    );
}

export default AddEmployee;