import { useEffect, useState } from "react";

import AppLinkButton from "../../../commons/buttons/AppLinkButton";
import AppLoading from "../../../commons/AppLoading";
import CompanyAPI from "../../company/CompanyAPI";
import CustomCard from "../../../commons/cards/Card";
import HallAppCenterContainer from "../../../commons/containers/HallAppCenterContainer";
import HallAppContainer from "../../../commons/containers/HallAppContainer";
import HallAppToast from "../../../commons/HallAppToast";
import ModalCompanyForm from "../../company/components/ModalCompanyForm";
import UserService from "../../../auth/UserService";

const service = new UserService()
const companyAPI = new CompanyAPI()

function HomeFirstAccess({ updateFirstAccess }) {
    const [userLogged, setUserLogged] = useState(null)
    const [isLoading, setIsloading] = useState(false)

    useEffect(() => {
        checkUser();
    }, []);

    const checkUser = () => {
        setUserLogged(service.getUserData())
    };

    async function updateMe() {
        try {
            setIsloading(true)
            const response = await companyAPI.findAllMe();

            if (response.data.length > 0) {
                const data = response.data[0];
                companyAPI.setCompanyManagment(data)
                companyAPI.setCompaniesLocalStorage(response.data)
                checkUser();
                updateFirstAccess()
                HallAppToast.success()
            }
        } catch(_) {
            HallAppToast.genericError()
            
        } finally {
            setIsloading(false)
        }
       
    }

    return (
        <>
            <HallAppContainer>
                <div>
                    <CustomCard>
                        <p className="hallapp-title">Para Proprietários</p>
                        <p style={{ fontSize: '16px' }} className="hallapp-subtitle">Caso você seja o responsável ou
                            proprietário de um negócio, clique no botão abaixo para criar a conta da sua empresa.</p>
                        <ModalCompanyForm updateFirstAccess={updateFirstAccess} />
                    </CustomCard>
                </div>

                <div style={{ marginTop: '50px' }}>
                    <CustomCard>
                        <p className="hallapp-title">Para Funcionários</p>
                        <p style={{ fontSize: '16px' }} className="hallapp-subtitle">Se você já trabalha em um estabelecimento que utiliza o HALLAPP, solicite ao administrador do sistema que adicione o
                            seu e-mail <span style={{ fontWeight: 'bold' }}>{userLogged?.email}</span> no cadastro de funcionários. Depois de cadastrado, clique em atualizar ou faça o login novamente.</p>


                        {
                            isLoading ? <HallAppCenterContainer> <AppLoading /></HallAppCenterContainer> :
                                <AppLinkButton onClick={updateMe} width='100%' name='atualizar' />
                        }
                    </CustomCard>
                </div>

            </HallAppContainer>
        </>
    );
}

export default HomeFirstAccess;