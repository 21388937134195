import './AuthContainer.scss';

import AppName from "../commons/AppName";

const AuthContainer = ({ children }) => {
    return (
        <div className="auth-container-page">
            <div className='auth-app-logo'>
                <AppName />
            </div>
            <div className='auth-content'>
                {children}
            </div>
        </div>

    );
};
export default AuthContainer;