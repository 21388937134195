import ApiService from "../../commons/services/ApiService";

const apiService = new ApiService();

export default class SchedulerConfigAPI {

    URI_V1 = "/v1/scheduler-config"

    configWeeklySchedule = async (data: any) => {
        return await apiService.post(`${this.URI_V1}/weekly-schedule`, data);
    };

    getConfigEmployee = async (companyId: any) => {
        return await apiService.get(`${this.URI_V1}/employee/weekly-schedule?companyId=${companyId}`);
    };

    deleteConfigWeeklySchedule = async (companyId: any, dayOfWeek: any) => {
        return await apiService.delete(`${this.URI_V1}/weekly-schedule?companyId=${companyId}&dayOfWeek=${dayOfWeek}`);
    };

    getClearances = async (employeeId: any) => {
        return await apiService.get(`${this.URI_V1}/employee/${employeeId}/clearances`);
    };

    closedScheduler = async (employeeId: any, data: any) => {
        return await apiService.post(`${this.URI_V1}/employee/${employeeId}/closed`, data);
    };

    removeClosedScheduler = async (id: any, companyId: any) => {
        return await apiService.delete(`${this.URI_V1}/closed/${id}?companyId=${companyId}`);
    };

    createLayout = async (data: any) => {
        return await apiService.postFormData(`${this.URI_V1}/layout`, data);
    };

    getLayout = async () => {
        return await apiService.get(`${this.URI_V1}/layout`);
    };

    updateLayout = async (layoutId: any, data: any) => {
        return await apiService.putFormData(`${this.URI_V1}/layout/${layoutId}`, data);
    };

}
