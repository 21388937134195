import SchedulerConfigTimes from "./SchedulerConfigTimes";

function SchedulerConfig() {

    return (
        <>
            <div className="container">
                <h4>Agenda Semanal</h4>
                <hr></hr>
                <SchedulerConfigTimes />
            </div>
        </>

    );
}

export default SchedulerConfig;