import './RecoveryPassword.scss';

import * as formik from 'formik';
import * as yup from 'yup';

import React, { useState } from 'react';

import AppButton from '../../commons/buttons/AppButton';
import AppInput from '../../commons/inputs/AppInput';
import AppLinkButton from '../../commons/buttons/AppLinkButton';
import AppLoading from '../../commons/AppLoading';
import AuthContainer from '../AuthContainer';
import { Form } from 'react-bootstrap';
import HallAppContainer from '../../commons/containers/HallAppContainer';
import HallAppToast from '../../commons/HallAppToast';
import { ToastContainer } from 'react-toastify';
import UserService from '../UserService';
import { useNavigate } from 'react-router-dom';

const RecoveryPassword = () => {

    const [showStep, setShowStep] = useState(false)

    const service = new UserService();

    const navigate = useNavigate();

    const toLogin = () => {
        navigate('/login');
    };

    const [isLoading, setIsLoading] = useState(false)
    const { Formik } = formik;

    const schema = yup.object().shape({
        email: yup.string().email('Email inválido').required('Email é obrigatório'),
    });

    const recoveryPassword = async (values, { setErrors }) => {
        setIsLoading(true)
        service.resetPassword(values)
            .then((_) => {
                HallAppToast.success();
                setShowStep(true)
            }).catch((_) => {
                HallAppToast.error('E-mail não encontrado');
                setErrors({ email: 'E-mail não encontrado.' });
            }).finally((_) => setIsLoading(false))
    };

    return (
        <AuthContainer>
            {
                <div>
                    {
                        !showStep && <Formik
                            validationSchema={schema}
                            onSubmit={recoveryPassword}
                            initialValues={{
                                email: '',
                            }}
                        >
                            {({ handleSubmit, handleChange, values, touched, errors }) => (
                                <>
                                    <HallAppContainer>
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <p className='login-txt-info hallapp-title text-color'>Recuperar senha</p>
                                            <p className='hallapp-subtitle text-color'>Informe o email que deseja recuperar a senha</p>
                                            <AppInput
                                                errors={errors}
                                                touched={touched}
                                                name='email'
                                                onChange={handleChange}
                                                value={values.email}
                                                type='email'
                                                label='E-mail' />


                                            {isLoading ? <AppLoading /> :
                                                <>
                                                    <AppButton type="submit" width='100%' name='recuperar' />
                                                    <div style={{ marginTop: '10px' }}>
                                                        <AppLinkButton onClick={() => toLogin()} type="submit" width='100%' name='VOLTAR' />
                                                    </div>
                                                </>

                                            }
                                        </Form>
                                    </HallAppContainer>
                                </>

                            )}
                        </Formik>
                    }

                    {showStep && <div style={{ textAlign: 'center' }}>
                        <HallAppContainer>
                            <h1 className='text-color '>Redefinição de Senha</h1>
                            <p className='text-color hallapp-subtitle'>Um e-mail foi enviado com um link para redefinir sua senha. Por favor, siga estes passos:</p>
                            <li>Acesse sua caixa de entrada.</li>
                            <hr></hr>
                            <li>Procure pelo e-mail com o assunto "Redefinição de Senha".</li>
                            <hr></hr>
                            <li>Clique no link contido no e-mail para definir uma nova senha.</li>
                            <hr></hr>
                            <AppLinkButton onClick={() => toLogin()} type="submit" width='100%' name='VOLTAR' />
                        </HallAppContainer>
                    </div>

                    }
                    <ToastContainer />
                </div>
            }
        </AuthContainer>

    );
};

export default RecoveryPassword;