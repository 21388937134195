import "react-datepicker/dist/react-datepicker.css";
import '../Commands.scss';

import { Col, Form, Row } from "react-bootstrap";

import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { format } from "date-fns";
import pt from 'date-fns/locale/pt-BR';
import { useState } from "react";

addLocale('pt', {
    firstDayOfWeek: 1,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar',
    dateFormat: 'dd/mm/yyyy',
    weekHeader: 'Sem',
});
const CommandFilter = ({ changeStatus, changeDates }) => {
    const [dates, setDates] = useState([new Date(), new Date()]);
    const [hasChange, setHasChange] = useState(false);


    const handleCalendarHide = () => {
       if (hasChange) {
        if (changeDates) {
            const [startDate, endDate] = dates
            changeDates(formatDate(startDate), formatDate(endDate))
          }
       }
    }
    
    function formatDate(date) {
        if (date === null || date === undefined) return null;
        return format(date, 'yyyy-MM-dd', { locale: pt })
    }

    function onChangeDates(dates) {
      setDates(dates)
      setHasChange(true)
    }

    return (
        <div
            className='d-flex justify-content-center'
            style={{
                zIndex: 999,
                backgroundColor: '#fff',
                position: 'absolute',
                left: 0,
                right: 0,
                width: '100%',
                height: '60px',
                overflowX: 'hidden'
            }}>
            <Row style={{ paddingTop: '10px', padding: '4px' }}>
                <Col>
                    <Calendar maxDate={new Date()}
                        style={{height: '10px'}}
                        locale="pt"
                        dateFormat="dd/mm/y"
                        value={dates} 
                        onChange={(e) => onChangeDates(e.value)}
                        selectionMode="range"
                        readOnlyInput
                        onHide={handleCalendarHide}
                        hideOnRangeSelection />
                </Col>
                <Col>
                    <Form.Select className="select-command-filter" aria-label="Default select example" onChange={(e) => changeStatus(e.target.value)}>
                        <option value="ALL">Todos</option>
                        <option value="PAID">Pago</option>
                        <option value="PARTIAL_PAYMENT">Pago Parcialmente</option>
                        <option value="WAITING_FOR_PAYMENT">Aguardando Pagamento</option>
                    </Form.Select>
                </Col>
            </Row>
        </div>
    );
};

export default CommandFilter;