import './Card.scss';

import Card from 'react-bootstrap/Card';

function CustomCard({ children, backgroundColor, minHeight }) {
    return (
        <Card className='hallapp-card' body style={{backgroundColor: backgroundColor, minHeight: minHeight}}>
            <div >
                {children}
            </div>
        </Card>
    );
}

export default CustomCard;