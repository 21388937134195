import { format } from "date-fns";
import pt from 'date-fns/locale/pt-BR';

export const isNullOrEmpty = (value) => {
  return value === null || value === undefined || value === "";
}

export function formatInReal(numero) {
  if (isNaN(numero)) numero = 0;
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(numero);
}

export function normalizeString(str) {
  return str
    .toLowerCase()  // Converte para minúsculas
    .normalize('NFD')  // Normaliza a string para permitir a remoção de acentos
    .replace(/[\u0300-\u036f]/g, '');  // Remove os acentos
}

export function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adiciona 1 ao mês e garante dois dígitos
  const day = String(date.getDate()).padStart(2, '0'); // Garante dois dígitos

  return `${year}-${month}-${day}`;
}

export function formatCurrencyInputBR(value) {
  return value.toString()
    .replace('R$', '')
    .replace('.', '')
    .replace(',', '.')
}

export function formatDateTime(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Janeiro é 0
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export function formatDate(dateString) {
  const [year, month, day] = dateString.split('-'); // Divide a string diretamente
  return `${day}/${month}/${year}`; // Retorna no formato desejado
}

export function formatDateInEN(date) {
  if (isNaN(date)) return null;
  return format(date, 'yyyy-MM-dd', { locale: pt })
}

export function formatTransactionType(type) {
  switch (type) {
    case 'CREDIT_CARD':
      return 'Crédito';
    case 'DEBIT_CARD':
      return 'Debito';
    case 'PIX':
      return 'PIX';
    case 'MONEY':
      return 'Dinheiro';
    default:
      return 'Tipo desconhecido';
  }
}

export function getCurrentTimestamp() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hour = String(now.getHours()).padStart(2, '0');
  const minute = String(now.getMinutes()).padStart(2, '0');
  const second = String(now.getSeconds()).padStart(2, '0');
  const millisecond = String(now.getMilliseconds()).padStart(3, '0');
  
  const nanosecondPart = (performance.now() % 1).toFixed(9).slice(2); 

  return `${year}${month}${day}${hour}${minute}${second}${millisecond}${nanosecondPart}`;
}

export function displayName(fullName) {
  if (fullName && fullName.includes(' ')) {
    const nameParts = fullName.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];
    return `${capitalize(firstName)} ${capitalize(lastName)}`;
  }
  return capitalize(fullName || ''); // Retorna o nome completo normalizado ou uma string vazia
};

// Função para capitalizar a primeira letra e deixar o restante em minúsculas
const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export function compressImage(file, maxWidth = 800, quality = 0.7) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Define as dimensões do canvas
        let width = img.width;
        let height = img.height;

        // Redimensiona se necessário
        if (width > maxWidth) {
          height = (maxWidth / width) * height;
          width = maxWidth;
        }

        canvas.width = width;
        canvas.height = height;

        // Desenha a imagem no canvas
        ctx.drawImage(img, 0, 0, width, height);

        // Converte o canvas para um Blob (compressão)
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(new File([blob], file.name, { type: file.type }));
            } else {
              reject(new Error('Erro ao comprimir a imagem.'));
            }
          },
          file.type,
          quality // Qualidade de compressão (0.1 a 1)
        );
      };
      img.onerror = (error) => reject(error);
    };
    reader.onerror = (error) => reject(error);
  });
}