import "./PermissionError.scss";

import React from "react";

const PermissionError = () => {
  return (
    <div className="error-page">
      <div className="error-container">
        <h1 className="error-code">404</h1>
        <h2 className="error-message">Página Não Encontrada</h2>
        <p className="error-description">
          Desculpe, a página que você está procurando não existe.
        </p>
      </div>
    </div>
  );
};

export default PermissionError;