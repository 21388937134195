import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";

import AppLoading from "../../commons/AppLoading";
import CardCommand from "./components/CardCommand";
import CommandAPI from "./CommandAPI";
import FloatingButton from "../../commons/buttons/FloatingButton";
import HallAppCenterContainer from "../../commons/containers/HallAppCenterContainer";
import InfiniteScroll from "react-infinite-scroll-component";
import ModalCommand from "./components/Command";
import PullToRefresh from "react-simple-pull-to-refresh";
import ReportModalFilter from "../report/components/ReportModalFilter";
import SecondaryFloatingButton from "../../commons/buttons/SecondaryFloatingButton";
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { formatDateInEN } from "../../commons/util/HallAppUtil";

function CommandsReception() {
    const commandAPI = new CommandAPI();

    const [commands, setCommands] = useState([])
    const [totalResultRows, setTotalResultRows] = useState(0);
    const [isLoadingPage, setIsLoadingPage] = useState(true)
    const [showModalFilter, setShowModalFilter] = useState(false)
    const [showModalCommand, setShowModalCommand] = useState(false)

    const [filter, setFilter] = useState({
        page: 0,
        limit: 50,
        status: '',
        startDate: formatDateInEN(new Date()),
        endDate: formatDateInEN(new Date()),
    })

    const findAllCommands = async () => {
        try {
            const response = await commandAPI.advancedSearch(filter)
            setCommands(prevItems => [...prevItems, ...response.data.content]);
            setTotalResultRows(response.data.totalResultRows)
        } catch (_) {
            setCommands([])
            setTotalResultRows(0)
        } finally {
            setIsLoadingPage(false)
        }
    };

    useEffect(() => {
        if (filter.page === 0) {
            setCommands([])
            setTotalResultRows(0)
        }
        findAllCommands(filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);


    async function getFilter(data) {
        setShowModalFilter(false);
        setFilter({
            ...filter,
            page: 0,
            startDate: data.startDate,
            endDate: data.endDate,
            status: data.commandStatus,
            employeeId: data.employeeId,
            paymentType: data.paymentType
        })
    }

    async function refresh() {
        setCommands([])
        setFilter({
            ...filter,
            page: 0
        })
    }

    return (
        <div className="container">
            <PullToRefresh onRefresh={refresh}>
                {isLoadingPage ? <HallAppCenterContainer><AppLoading /></HallAppCenterContainer> :
                    <InfiniteScroll
                        dataLength={commands.length}
                        key={commands.length}
                        next={() => {
                            if (commands.length < totalResultRows) {
                                setFilter({
                                    ...filter,
                                    page: filter.page + 1,
                                })
                            }
                        }}
                        hasMore={true}
                        loader={commands.length < totalResultRows && <HallAppCenterContainer><AppLoading /></HallAppCenterContainer>}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Yay! Você já viu de tudo</b>
                            </p>
                        }
                    >
                        <Row style={{ margin: 0 }}>
                            {
                                commands.map(data => (
                                    <Col xs={12} sm={12} md={4} lg={4} key={data.id} style={{ marginBottom: '10px' }}>
                                        <CardCommand updateCommands={() => {
                                            setFilter({
                                                ...filter,
                                                page: 0,
                                            })
                                        }} command={data} />
                                    </Col>

                                ))
                            }
                        </Row>
                    </InfiniteScroll>}

            </PullToRefresh>
            <FloatingButton icon={faSearch} onClick={() => {
                setShowModalFilter(true);
            }} />

            <SecondaryFloatingButton icon={faAdd} onClick={() => {
                setShowModalCommand(true);
            }} />

            <ReportModalFilter
                getFilter={getFilter}
                show={showModalFilter}
                closeShow={() => {
                    setShowModalFilter(false);
                }} />


            {
                showModalCommand && <ModalCommand
                    show={showModalCommand}
                    findAllCommands={async () => {
                        setIsLoadingPage(true)
                        setFilter({
                            ...filter,
                            page: 0,
                        })
                        setIsLoadingPage(false)
                    }}
                    closeShow={() => {
                        setShowModalCommand(false);
                    }} />
            }

        </div>
    )
}

export default CommandsReception;