import * as formik from 'formik';
import * as yup from 'yup';

import { Form, Modal } from "react-bootstrap";

import AppButton from '../../../commons/buttons/AppButton';
import AppInput from '../../../commons/inputs/AppInput';
import AppLoading from "../../../commons/AppLoading";
import CalendarInput from "../../../commons/inputs/CalendarInput";
import CompanyAPI from '../../company/CompanyAPI';
import CustomContainer from "../../../commons/containers/CustomContainer";
import HallAppCenterContainer from "../../../commons/containers/HallAppCenterContainer";
import HallAppToast from '../../../commons/HallAppToast';
import SchedulerConfigAPI from '../SchedulerConfigAPI';
import UserService from '../../../auth/UserService';
import { formatDateToYYYYMMDD } from '../../../commons/util/HallAppUtil';
import { useMask } from '@react-input/mask';
import { useState } from "react";

function SchedulerClearancesModal({ closeShow, show, fetchAll }) {

    const [serviceDate, setServiceDate] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [type, setType] = useState('ALL_DAY');

    const companyAPI = new CompanyAPI();
    const schedulerAPI = new SchedulerConfigAPI();
    const userAPI = new UserService();

    const [initialValues, setInitialValues] = useState({
        startTime: '',
        endTime: ''
    });

    const { Formik } = formik;

    const schema = yup.object().shape({
        startTime: yup.string(),
        endTime: yup.string()
    });


    function handleChangeDate(date) {
        setServiceDate(date)
    }

    const create = async (values, { resetForm }) => {
        const data = {
            ...values,
            date: serviceDate || formatDateToYYYYMMDD(new Date()),
            type: type,
            companyId: companyAPI.getCompanyManagment().id,
        }

        if (type === 'TIME' && ((values.startTime === null || values.startTime === "") 
            || (values.endTime === null || values.endTime === ""))) {
            HallAppToast.error('Informe o horario de inicio e fim.');
            return;
        }
        
        try {
            setLoading(true)
            await schedulerAPI.closedScheduler(userAPI.getUserData().id, data);
            HallAppToast.success();
            resetForm();
            if(fetchAll) {
                fetchAll();
            }
        } catch (_) {
            HallAppToast.genericError();
        } finally {
            setLoading(false)
        }
    }

    const startTime = useMask({ mask: '__:__', replacement: { _: /\d/ } });
    const endTime = useMask({ mask: '__:__', replacement: { _: /\d/ } });

    return (
        <>
            <Modal show={show} fullscreen={true} onHide={() => closeShow()}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: 'center', fontSize: '15px' }}>
                        ADICIONAR FOLGA
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CustomContainer>
                        <Formik
                            validationSchema={schema}
                            onSubmit={create}
                            initialValues={initialValues}
                            enableReinitialize={true}
                        >
                            {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                                <Form noValidate onSubmit={handleSubmit}>

                                    <div className="commando-form-service-date" style={{ marginBottom: '20px' }}>
                                        <CalendarInput
                                            selectionMode="single"
                                            setDates={setStartDate}
                                            changeDates={handleChangeDate}
                                            values={startDate} />
                                    </div>

                                    <div style={{ marginBottom: '10px' }}>
                                        <Form.Check
                                            checked={type === 'ALL_DAY'}
                                            inline
                                            label="Dia todo"
                                            name="group1"
                                            type='radio'
                                            value="ALL_DAY"
                                            onChange={(e) => setType(e.target.value)}
                                            id={`inline-radio-1`}
                                        />

                                        <Form.Check
                                            checked={type === 'TIME'}
                                            inline
                                            label="Por Horário"
                                            name="group1"
                                            type='radio'
                                            value="TIME"
                                            onChange={(e) => setType(e.target.value)}
                                            id={`inline-radio-2`}
                                        />
                                    </div>

                                    {
                                        type === 'TIME' && <AppInput errors={errors}
                                            touched={touched}
                                            mask={startTime}
                                            name='startTime'
                                            onChange={handleChange}
                                            value={values.startTime}
                                            label='Inicio' />
                                    }

                                    {
                                        type === 'TIME' && <AppInput errors={errors}
                                            touched={touched}
                                            mask={endTime}
                                            name='endTime'
                                            onChange={handleChange}
                                            value={values.endTime}
                                            label='Fim' />
                                    }

                                    {isLoading ? <HallAppCenterContainer><AppLoading /> </HallAppCenterContainer> :
                                        <AppButton type="submit" width='100%' name='adicionar' />
                                    }
                                </Form>
                            )}
                        </Formik>
                    </CustomContainer>
                </Modal.Body>
            </Modal>
        </>

    );
}

export default SchedulerClearancesModal;