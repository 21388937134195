import { useEffect, useState } from 'react';

import AppLoading from '../../commons/AppLoading';
import CompanyAPI from '../company/CompanyAPI';
import HallAppToast from '../../commons/HallAppToast';
import PullToRefresh from 'react-simple-pull-to-refresh';
import SchedulerConfigAPI from './SchedulerConfigAPI';
import Weeks from './components/Weeks';

function SchedulerConfigTimes() {
    const schedulerAPI = new SchedulerConfigAPI();
    const companyAPI = new CompanyAPI();
    const [configWeeks, setConfigWeeks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getConfigWeek = async () => {
        try {
            setIsLoading(true)
            const resonse = await schedulerAPI.getConfigEmployee(companyAPI.getCompanyManagment().id);
            setConfigWeeks(resonse.data)
        } catch (_) {
            HallAppToast.genericError()
        } finally {
            setIsLoading(false)
        }
    };
    useEffect(() => {
        getConfigWeek()
    }, []);


    return (
        <>
            <PullToRefresh onRefresh={getConfigWeek}>
                {isLoading ? <div style={{ textAlign: 'center' }}><AppLoading /></div> :
                    configWeeks.map((data) => {
                        return <>
                            <Weeks weekConfig={data} />
                            <hr></hr>
                        </>
                    })
                }
            </PullToRefresh>

        </>

    );
}

export default SchedulerConfigTimes;