import { SwipeAction, SwipeableList, SwipeableListItem, TrailingActions } from 'react-swipeable-list';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";

import AppLoading from '../../../commons/AppLoading';
import { Col } from 'react-bootstrap';
import CompanyAPI from '../../company/CompanyAPI';
import ConfirmModal from '../../../commons/modal/ConfirmModal';
import CustomCard from "../../../commons/cards/Card";
import FloatingButton from "../../../commons/buttons/FloatingButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HallAppContainer from '../../../commons/containers/HallAppContainer';
import HallAppToast from '../../../commons/HallAppToast';
import PullToRefresh from 'react-simple-pull-to-refresh';
import SchedulerClearancesModal from './SchedulerClearancesModal';
import SchedulerConfigAPI from "../SchedulerConfigAPI";
import UserService from "../../../auth/UserService";
import { formatDate } from "../../../commons/util/HallAppUtil";

function SchedulerClearances() {
    const schedulerAPI = new SchedulerConfigAPI();
    const userAPI = new UserService();
    const companyAPI = new CompanyAPI();

    const [isLoading, setIsLoading] = useState(true);
    const [clearances, setClearances] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [isLoadingModalDelete, setIsLoadingModalDelete] = useState(false)
    const [currentClosed, setCurrentClosed] = useState([]);

    const getClearances = async () => {
        try {
            setIsLoading(true)
            const resonse = await schedulerAPI.getClearances(userAPI.getUserData().id);
            setClearances(resonse.data)
        } finally {
            setIsLoading(false)
        }
    };
    useEffect(() => {
        getClearances()
    }, []);

    function getStatus(value) {
        if (value === 'ACTIVE') {
            return 'Ativo'
        }
        if (value === 'INACTIVE') {
            return 'Cancelado'
        }
        return '--'
    }

    const remove = async () => {
        try {
            setIsLoadingModalDelete(true)
            await schedulerAPI.removeClosedScheduler(currentClosed.id, companyAPI.getCompanyManagment().id);
            await getClearances()
            HallAppToast.success();
            setShowModalDelete(false)
        } catch (_) {
            HallAppToast.genericError();
        } finally {
            setIsLoadingModalDelete(false)
        }
    };

    const deleteAction = (value) => (
        <TrailingActions>
            <SwipeAction
                destructive={false}
                onClick={() => {
                    setCurrentClosed(value)
                    setShowModalDelete(true)
                }}
            >
                <div className="swipe-container-company">
                    <div className="swipe-delete-company">
                        <FontAwesomeIcon icon={faTrash} size="lg" />
                        <div>REMOVER</div>
                    </div>
                </div>
            </SwipeAction>
        </TrailingActions>
    );

    return (
        <>
            <div className='container'>
                <PullToRefresh onRefresh={getClearances}>

                    <h4>Feriados e Folgas</h4>
                    <hr></hr>
                    {isLoading ? <AppLoading /> :
                        clearances.map(value => (
                            <Col xs={12} sm={12} md={4} lg={4} key={value.id} style={{ marginBottom: '10px' }}>
                                <SwipeableList style={{ width: '100%' }}>
                                    <SwipeableListItem
                                        trailingActions={deleteAction(value)}
                                        key={value.id}
                                        fullSwipe={false}
                                    >
                                        <div style={{ width: '100%' }}>
                                            <CustomCard backgroundColor={value.isValidDate ? '' : '#DCDCDC'}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <p style={{ margin: 0, fontWeight: 'bold' }}>Data</p>
                                                    <p style={{ margin: 0 }}>{formatDate(value.closedDate)}</p>
                                                </div>

                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <p style={{ margin: 0, fontWeight: 'bold' }}>Horário Inicio</p>
                                                    <p style={{ margin: 0 }}>{value.startTime || '--'}</p>
                                                </div>

                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <p style={{ margin: 0, fontWeight: 'bold' }}>Horário Fim</p>
                                                    <p style={{ margin: 0 }}>{value.endTime || '--'}</p>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <p style={{ margin: 0, fontWeight: 'bold' }}>Status</p>
                                                    <p style={{ margin: 0 }}>{getStatus(value.status)}</p>
                                                </div>
                                            </CustomCard>
                                        </div>
                                    </SwipeableListItem>
                                </SwipeableList>
                            </Col>

                        ))
                    }


                    <div style={{ marginTop: '30px' }}></div>

                    <FloatingButton icon={faAdd} onClick={async () => {
                        setShowModal(true);
                    }} />

                    {
                        showModalDelete && <ConfirmModal show={showModalDelete}
                            title='Remover'
                            isLoading={isLoadingModalDelete}
                            confirm={remove}
                            closeModal={() => setShowModalDelete(false)}
                        >
                            Deseja remover o registro <span style={{ fontWeight: 'bold' }}>{formatDate(currentClosed?.closedDate)}
                                <br></br> {currentClosed?.startTime || '-'} / {currentClosed?.endTime || '-'} </span>?
                        </ConfirmModal>
                    }

                    {
                        showModal && <SchedulerClearancesModal
                            show={showModal}
                            fetchAll={getClearances}
                            closeShow={() => {
                                setShowModal(false);
                            }} />
                    }
                </PullToRefresh>
            </div>
        </>

    );
}

export default SchedulerClearances;