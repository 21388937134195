import './ModalCategory.scss'

import * as formik from 'formik';
import * as yup from 'yup';

import { Alert, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";

import AppButton from "../../../commons/buttons/AppButton";
import AppInput from "../../../commons/inputs/AppInput";
import AppLoading from "../../../commons/AppLoading";
import CategoryAPI from "../CategoryAPI";
import CompanyAPI from "../../company/CompanyAPI";
import { Form } from 'react-bootstrap';
import HallAppCenterContainer from "../../../commons/containers/HallAppCenterContainer";
import HallAppToast from "../../../commons/HallAppToast";
import { compressImage } from '../../../commons/util/HallAppUtil';
import noImg from '../../../assets/images/no-img.png'

function ModalCategory({ closeShow, show, findAllCategories, categorySelected }) {
    const [initialValues, setInitialValues] = useState({
        category: '',
    });
    const [imgLogo, setImgLogo] = useState();

    const [isLoading, setLoading] = useState(false);

    const { Formik } = formik;

    const schema = yup.object().shape({
        category: yup.string().required('Informe o nome da categoria').max(50, 'Categoria deve ter no maximo 50 cracteres')
    });

    const categoryAPI = new CategoryAPI();
    const companyAPI = new CompanyAPI();

    const create = async (values, { resetForm }) => {
        const data = {
            name: values.category,
            companyId: companyAPI.getCompanyManagment().id
        }
        const formData = new FormData();
        formData.append("dto", new Blob([JSON.stringify(data)], { type: "application/json" }));

        if (imgLogo !== null && imgLogo !== undefined) {
            const logoCompress = await compressImage(imgLogo);
            formData.append("imgCategory", logoCompress);
        }

        setLoading(true)
        try {
            if (categorySelected != null && Object.keys(categorySelected).length > 0) {
                await categoryAPI.update(categorySelected.id, formData);
            } else {
                await categoryAPI.create(formData);
                resetForm()
                setImgLogo(null)
            }

            setLoading(false)
            HallAppToast.success();

            if (findAllCategories) {
                findAllCategories()
            }
        } catch (_) {
            setLoading(false)
            HallAppToast.genericError();
        }
    }

    function handleChangeImgLogo(e) {
        setImgLogo(e.target.files[0]);
    }

    useEffect(() => {
        if (categorySelected != null && Object.keys(categorySelected).length > 0) {
            setInitialValues({
                category: categorySelected.name,
            })
        }
    }, [categorySelected]);

    return (
        <>
            <Modal show={show} fullscreen={false} centered={true} onHide={() => closeShow()}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: 'center', fontSize: '15px' }}>
                        ADICIONAR CATEGORIA
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HallAppCenterContainer>
                        <Alert key='info' variant='info'>
                            Por favor, insira um nome descritivo para a categoria, como 'Cabelo' ou 'Serviços relacionados a cabelo', que abranja claramente os serviços que você oferece.
                        </Alert>
                        <Formik
                            validationSchema={schema}
                            onSubmit={create}
                            initialValues={initialValues}
                            enableReinitialize={true}
                        >
                            {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <div className="category-pic" >
                                        <img src={(imgLogo && URL.createObjectURL(imgLogo)) || (categorySelected?.logo || noImg)} alt="logotipo" />
                                    </div >
                                    <div style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                    }}>
                                        <label
                                            htmlFor="file-upload-logo"
                                            style={{
                                                display: 'inline-block',
                                                padding: '10px 20px',
                                                backgroundColor: '#198754',
                                                color: '#fff',
                                                borderRadius: '15px',
                                                cursor: 'pointer',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                marginTop: '20px',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            <span style={{
                                                color: '#fff', textAlign: 'center', justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>ADICIONAR IMAGEM</span>
                                        </label>
                                    </div>
                                    <input
                                        id="file-upload-logo"
                                        type="file"
                                        onChange={handleChangeImgLogo}
                                        style={{ display: 'none' }}
                                    />
                                    <AppInput errors={errors}
                                        touched={touched}
                                        name='category'
                                        onChange={handleChange}
                                        value={values.category}
                                        type='text'
                                        label='Categoria' />

                                    {isLoading ? <HallAppCenterContainer><AppLoading /> </HallAppCenterContainer> :
                                        <AppButton type="submit" width='100%' name='adicionar' />
                                    }
                                </Form>
                            )}
                        </Formik>
                    </HallAppCenterContainer>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalCategory;