import './Template.scss';

import React, { useEffect, useState } from 'react';
import { selectedCompany, setCompanies } from '../redux/actions/companyAction';
import { useDispatch, useSelector } from 'react-redux';

import Dropdown from 'react-bootstrap/Dropdown';
import { Nav } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SidebarItem from './SidebarItem';
import SidebarSubItem from './SidebarSubItem';
import UserService from '../auth/UserService';
import { displayName } from '../commons/util/HallAppUtil';
import packageJson from '../../package.json'; // Caminho relativo ao arquivo

const service = new UserService()

const menuItems = [
    {
        label: 'Estabelecimentos',
        path: 'meus-estabelecimentos',
        validateAdmin: true,
        showMenuReception: false
    },
    {
        label: 'Funcionarios',
        path: 'funcionarios',
        validateAdmin: true,
        showMenuReception: false
    },
    {
        label: 'Categorias e Serviços',
        path: 'servicos',
        validateAdmin: true,
        showMenuReception: false
    },
    {
        label: 'Comandas',
        path: 'comandas',
        validateAdmin: false,
        showMenuReception: false
    },
    {
        label: 'Comandas',
        path: 'comandas-recepcao',
        validateAdmin: false,
        showMenuReception: true
    },
    {
        label: 'Relatorio',
        path: 'relatorio',
        validateAdmin: true,
        showMenuReception: false
    },
    {
        label: 'Agenda',
        path: '',
        children: [
            { label: 'Configuração da Agenda', path: 'configurar-agenda', validateAdmin: true, main: 'Agenda', showMenuReception: false },
            { label: 'Agenda Semanal', path: 'agenda-semanal', validateAdmin: false, main: 'Agenda', showMenuReception: false },
            { label: 'Feriados e Folgas', path: 'folgas', validateAdmin: false, main: 'Agenda', showMenuReception: false },

        ],
    },
];

const Sidebar = ({ show, handleClose }) => {
    const [userLogged, setUserLogged] = useState(null)
    const dispatch = useDispatch();

    const companyManagement = useSelector(state => state.company.companyManagement);
    const companiesUserLogged = useSelector(state => state.company.companies);


    useEffect(() => {
        const checkUser = () => {
            const user = service.getUserData();
            setUserLogged(user)
        };
        checkUser();
        getCompanies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function isUserAdmin() {
        const user = service.getUserData();
        return user.rules?.length !== 0 && user.rules?.includes("COMPANY");
    }

    function isReception() {
        const user = service.getUserData();
        return user.rules?.length !== 0 && user.rules?.includes("RECEPTION");
    }


    function getCompanies() {
        const companies = JSON.parse(localStorage.getItem('companies'));
        if (companies === null) {
            dispatch(setCompanies([]));
        } else {
            dispatch(setCompanies(companies));
        }
    }

    function setCompany(company) {
        dispatch(selectedCompany(company))
    }

    function getCompanyNameSelected() {
        if (companiesUserLogged?.length === 0) return 'Você pertence a nenhuma empresa'
        if (companyManagement?.unit === null) {
            return companyManagement?.name;
        }
        if (companyManagement?.name === undefined) {
            const c = JSON.parse(localStorage.getItem('company-management'))
            return `${c?.name} - ${c?.unit}`
        }
        return `${companyManagement?.name} - ${companyManagement?.unit}`
    }


    return (
        <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false} className="custom-sidebar">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Olá {userLogged?.name.split(' ')[0]}!
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Nav className="flex-column">
                    <hr></hr>
                    <Dropdown>
                        <Dropdown.Toggle className='dropdown-company' disabled={companiesUserLogged?.length === 0} style={{ width: '100%' }} variant="success" id="dropdown-basic">
                            {getCompanyNameSelected()}
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ width: '100%' }} >
                            {
                                companiesUserLogged?.map((company) => <Dropdown.Item onClick={() => setCompany(company)} eventKey={company.id}>
                                    {company?.name} - {company?.unit}
                                </Dropdown.Item>)
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                    <hr></hr>
                    {menuItems.filter((item) => {
                        if (item.validateAdmin && isUserAdmin()) {
                            return true;
                        }

                        if (isReception() && item.showMenuReception) {
                            return true;
                        }

                        if (!item.validateAdmin && (!isReception() && !item.showMenuReception)) {
                            return true;
                        }
                        return false;
                    }).map((item, index) => (
                        <SidebarSubItem key={index} {...item} handleClose={handleClose} isUserAdmin={isUserAdmin()} />
                    ))}
                    <hr></hr>
                    <SidebarItem label='Sair' path='login' handleClose={handleClose} />

                    <div className="profile-section">
                        <div className="profile-info">
                            <strong>{displayName(userLogged?.name)}</strong>
                            <span>{userLogged?.email}</span><br></br>
                            <span>Versão {packageJson.version}</span>
                        </div>
                    </div>
                </Nav>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default Sidebar;