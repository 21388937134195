import { HashRouter, Route, Routes } from "react-router-dom";

import ActivateAccount from "./auth/create/ActivateAccount";
import CategoriesAnServices from "./pages/services/CategoriesAndServices";
import Commands from "./pages/commands/Commands";
import CommandsReception from "./pages/commands/CommandReception";
import Companies from "./pages/company/Companies";
import CreateAccout from "./auth/create/CreateAccout";
import EmployeeInvite from "./pages/employee/pages/EmployeeInvite";
import Employess from "./pages/employee/Employees";
import { Fragment } from "react";
import Home from "./pages/home/Home";
import Install from "./auth/Install";
import Login from "./auth/login/Login";
import PermissionError from "./PermissionError";
import PrivateRoute from "./auth/PrivateRoute";
import ProtectedRoute from "./ProtectedRoute";
import RecoveryPassword from "./auth/recovery-password/RecoveryPassword";
import Report from "./pages/report/Report";
import SchedulerClearances from "./pages/scheduler/pages/SchedulerClearances";
import SchedulerConfig from "./pages/scheduler/SchedulerConfig";
import SchedulerLayoutConfig from './pages/scheduler/pages/SchedulerLayoutConfig';
import Template from "./template/Template";
import UpadatePassword from "./auth/update-password/UpdatePassword";

export const AppRoutes = () => {
  return (
    <HashRouter>
      <Fragment>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/instalar" element={<Install />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route element={<Template />}>
              <Route index element={<Home />} />
              <Route path="inicio" element={<Home />} />
              <Route path="meus-estabelecimentos" element={<ProtectedRoute requiredRole="COMPANY"><Companies /></ProtectedRoute>} />
              <Route path="agenda-semanal" element={<SchedulerConfig />} />
              <Route path="funcionarios" element={<ProtectedRoute requiredRole="COMPANY"><Employess /></ProtectedRoute>} />
              <Route path="comandas" element={<Commands />} />
              <Route path="comandas-recepcao" element={<CommandsReception />} />
              <Route path="servicos" element={<CategoriesAnServices />} />
              <Route path="relatorio" element={<ProtectedRoute requiredRole="COMPANY"><Report /></ProtectedRoute>} />
              <Route path="folgas" element={<SchedulerClearances />} />
              <Route path="configurar-agenda" element={<SchedulerLayoutConfig />} />
              <Route path="/erro" element={<PermissionError />} />
            </Route>
          </Route>
          <Route path="/criar-conta" element={<CreateAccout />} />
          <Route path="/convite/:token" element={<EmployeeInvite />} />
          <Route path="/recuperar-senha" element={<RecoveryPassword />} />
          <Route path="/redefinir-senha/:token" element={<UpadatePassword />} />
          <Route path="/ativar-conta/:token" element={<ActivateAccount />} />
        </Routes>
      </Fragment>
    </HashRouter>
  );
};