import { Navigate, Outlet } from "react-router-dom";

import UserService from "./auth/UserService";

const ProtectedRoute = ({ requiredRole, children }) => {
  const service = new UserService()
  const user = service.getUserData()

  // Verifica se o usuário está logado e possui a regra necessária
  if (!user || !user.rules || !user.rules.includes(requiredRole)) {
    return <Navigate to="/erro" replace />; // Redireciona para uma página de erro
  }

  return children;
};

export default ProtectedRoute;