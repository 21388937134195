import "react-datepicker/dist/react-datepicker.css";
import './Input.scss'

import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { formatDateInEN } from "../util/HallAppUtil";

addLocale('pt', {
    firstDayOfWeek: 1,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar',
    dateFormat: 'dd/mm/yyyy',
    weekHeader: 'Sem',
});
const CalendarInput = ({ values, changeDates, dateFormat = "dd/mm/yy", setDates, selectionMode="range", maxDate }) => {

    function onChangeDates(dates) {
        if (selectionMode === 'single') {
            changeDates(formatDateInEN(dates))
            setDates(dates)
        } else {
            const [startDate, endDate] = dates
            setDates(dates)
            changeDates(formatDateInEN(startDate), formatDateInEN(endDate))
        }
    }

    return (
        <Calendar
            style={{ width: '100%' }} 
            maxDate={maxDate}
            locale="pt"
            dateFormat={dateFormat}
            value={values}
            onChange={(e) => onChangeDates(e.value)}
            selectionMode={selectionMode}
            readOnlyInput
            hideOnRangeSelection />
    );
};

export default CalendarInput;