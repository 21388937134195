import AppLinkButton from "../../../commons/buttons/AppLinkButton";
import CompanyForm from "./CompanyForm";
import { Modal } from "react-bootstrap";
import { useState } from "react";

function ModalCompanyForm({ updateFirstAccess }) {

    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
    }

    function closeShow() {
        setShow(false)
        if (updateFirstAccess) {
            updateFirstAccess();
        }
    }

    return (
        <>
            <AppLinkButton onClick={() => handleShow(true)} width='100%' name='criar novo estabelecimento' />
            <Modal className='hallapp-establishment-modal' show={show} fullscreen={fullscreen} onHide={() => closeShow()}>
                <Modal.Header className='hallapp-establishment-modal custom-close-button' closeButton>
                    <Modal.Title style={{ textAlign: 'center', fontSize: '15px' }}>
                        NOVO ESTABELECIMENTO
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CompanyForm closeModal={closeShow} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalCompanyForm;