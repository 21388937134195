import "react-color-palette/css";

import { Col, Modal, Row } from "react-bootstrap";
import { ColorPicker, useColor } from "react-color-palette";

import AppButton from "../../../commons/buttons/AppButton";
import AppLinkButton from "../../../commons/buttons/AppLinkButton";

function ModalSchedulerLayoutColor({ closeModal, show, setBackgroundColor }) {
    const [color, setColor] = useColor("#561ecb");

    return (
        <Modal className='hallapp-modal' centered show={show} fullscreen={false} onHide={() => closeModal()}>
            <Modal.Header style={{ border: 'none', padding: '25px' }} closeButton>
                <Modal.Title>Alterar Cor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <ColorPicker hideInput={true} color={color} onChange={setColor} />

            <div style={{backgroundColor: color.hex, width: '100%', height: '60px', marginTop: '20px'}}>

            </div>
            </Modal.Body>
            <Modal.Footer style={{ border: 'none', padding: '25px' }}>
                <Row className="justify-content-end">
                    <Col xs="auto">
                        <AppLinkButton name="cancelar" onClick={() => closeModal()} />
                    </Col>
                    <Col xs="auto">
                        <AppButton name="confirmar" onClick={() => {
                            setBackgroundColor(color.hex);
                            closeModal();
                        }} />
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>

    );
}

export default ModalSchedulerLayoutColor;