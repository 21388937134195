import * as formik from 'formik';
import * as yup from 'yup';

import AppButton from "../../../commons/buttons/AppButton";
import AppInput from "../../../commons/inputs/AppInput";
import AppLoading from "../../../commons/AppLoading";
import CompanyAPI from "../../company/CompanyAPI";
import { Form } from 'react-bootstrap';
import HallAppCenterContainer from "../../../commons/containers/HallAppCenterContainer";
import HallAppToast from "../../../commons/HallAppToast";
import { Modal } from "react-bootstrap";
import SchedulerConfigAPI from '../SchedulerConfigAPI';
import { useMask } from '@react-input/mask';
import { useState } from "react";

function ModalTime({ closeShow, show, weekConfig }) {
    const [initialValues] = useState({
        startTimeMorning: weekConfig.isActive ? weekConfig?.startTimeMorning : '09:00',
        endTimeMorning: weekConfig.isActive ? weekConfig?.endTimeMorning : '12:00',
        startTimeAfternoon: weekConfig.isActive ? weekConfig?.startTimeAfternoon : '13:00',
        endTimeAfternoon: weekConfig.isActive ? weekConfig?.endTimeAfternoon : '18:00',
    });
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/; // Regex para validar formato de hora (HH:mm)

    const [isLoading, setLoading] = useState(false);

    const { Formik } = formik;

    const schema = yup.object().shape({
        startTimeMorning: yup
          .string()
          .matches(timeRegex, 'Horário inválido')
          .nullable()
          .test(
            'start-end-morning',
            'Preencha ambos os campos de manhã ou deixe ambos vazios',
            function (value) {
              const { endTimeMorning } = this.parent;
              return (
                (!value && !endTimeMorning) || (value && endTimeMorning && value < '13:00')
              );
            }
          ),
        endTimeMorning: yup
          .string()
          .matches(timeRegex, 'Horário inválido')
          .nullable()
          .test(
            'end-start-morning',
            'Preencha ambos os campos de manhã ou deixe ambos vazios',
            function (value) {
              const { startTimeMorning } = this.parent;
              return (
                (!value && !startTimeMorning) || (value && startTimeMorning && value < '13:00')
              );
            }
          ),
        startTimeAfternoon: yup
          .string()
          .matches(timeRegex, 'Horário inválido')
          .nullable()
          .test(
            'start-end-afternoon',
            'Preencha ambos os campos da tarde ou deixe ambos vazios',
            function (value) {
              const { endTimeAfternoon } = this.parent;
              return (
                (!value && !endTimeAfternoon) || (value && endTimeAfternoon && value >= '12:00')
              );
            }
          ),
        endTimeAfternoon: yup
          .string()
          .matches(timeRegex, 'Horário inválido')
          .nullable()
          .test(
            'end-start-afternoon',
            'Preencha ambos os campos da tarde ou deixe ambos vazios',
            function (value) {
              const { startTimeAfternoon } = this.parent;
              return (
                (!value && !startTimeAfternoon) || (value && startTimeAfternoon && value >= '13:00')
              );
            }
          ),
      });

    const companyAPI = new CompanyAPI();
    const schedulerAPI = new SchedulerConfigAPI();

    const create = async (values) => {
      const data = {
        ...values,
        companyId: companyAPI.getCompanyManagment().id,
        dayOfWeek: weekConfig.dayOfWeek,
      }
      
      try {
        setLoading(true)
        await schedulerAPI.configWeeklySchedule(data);
        HallAppToast.success();
      } catch(_) {
        HallAppToast.genericError();
      } finally {
        setLoading(false)
      }
    }

    const timeMaskStartTimeMorning = useMask({ mask: '__:__', replacement: { _: /\d/ } });
    const timeMaskEndTimeMorning = useMask({ mask: '__:__', replacement: { _: /\d/ } });
    const timeMaskStartTimeAfternoon = useMask({ mask: '__:__', replacement: { _: /\d/ } });
    const timeMaskEndTimeAfternoon = useMask({ mask: '__:__', replacement: { _: /\d/ } });
    return (
        <>
            <Modal show={show} fullscreen={false} centered={true} onHide={() => closeShow()}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: 'center', fontSize: '15px', textTransform: 'uppercase' }}>
                        CONFIGURAR HORARIO PARA {weekConfig.description}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        validationSchema={schema}
                        onSubmit={create}
                        initialValues={initialValues}
                        enableReinitialize={true}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <div className='row'>
                                    <h6>Periodo da manhã</h6>
                                    <div className='col'>
                                        <AppInput errors={errors}
                                            touched={touched}
                                            mask={timeMaskStartTimeMorning}
                                            name='startTimeMorning'
                                            onChange={handleChange}
                                            value={values.startTimeMorning}
                                            label='Inicio' />
                                    </div>
                                    <div className='col'>
                                        <AppInput errors={errors}
                                            touched={touched}
                                            name='endTimeMorning'
                                            onChange={handleChange}
                                            value={values.endTimeMorning}
                                            mask={timeMaskEndTimeMorning}
                                            type='text'
                                            label='Fim' />
                                    </div>
                                </div>

                                <div className='row'>
                                    <h6>Periodo da tarde</h6>
                                    <div className='col'>
                                        <AppInput errors={errors}
                                            touched={touched}
                                            name='startTimeAfternoon'
                                            onChange={handleChange}
                                            value={values.startTimeAfternoon}
                                            mask={timeMaskStartTimeAfternoon}
                                            type='text'
                                            label='Inicio' />
                                    </div>
                                    <div className='col'>
                                        <AppInput errors={errors}
                                            touched={touched}
                                            name='endTimeAfternoon'
                                            onChange={handleChange}
                                            value={values.endTimeAfternoon}
                                            mask={timeMaskEndTimeAfternoon}
                                            type='text'
                                            label='Fim' />
                                    </div>
                                </div>

                                {isLoading ? <HallAppCenterContainer><AppLoading /> </HallAppCenterContainer> :
                                    <AppButton type="submit" width='100%' name='salvar' />
                                }
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalTime;