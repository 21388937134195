import * as formik from 'formik';
import * as yup from 'yup';

import { useEffect, useState } from 'react';

import { Alert } from 'react-bootstrap';
import AppButton from '../../../commons/buttons/AppButton';
import AppInput from '../../../commons/inputs/AppInput';
import AppLoading from '../../../commons/AppLoading';
import CompanyAPI from '../../company/CompanyAPI';
import Form from 'react-bootstrap/Form';
import HallAppToast from '../../../commons/HallAppToast';
import Modal from 'react-bootstrap/Modal';
import Switch from '../../../commons/inputs/Switch';

function EmployeeModal({ show, closeModal, employee, updateListEmployees }) {
    const [isLoading, setIsLoading] = useState(false)
    const [isAdmin, setAdmin] = useState(false)
    const [isReception, setReception] = useState(false)

    const [showNotification, setShowNotification] = useState(false)
    const [initialValues, setInitialValues] = useState({
        email: '',
        isadmin: false,
    });
    const { Formik } = formik;

    const schema = yup.object().shape({
        email: yup.string().email('Email inválido').required('Email é obrigatório'),
    });

    useEffect(() => {
        if (employee) {
            const hasRuleCompany = employee.rules?.some(item => item.rule === 'COMPANY');
            setAdmin(hasRuleCompany)
            setInitialValues({ email: employee.email || '', isAdmin: hasRuleCompany });
        } else {
            setAdmin(false)
            setInitialValues({ email: '', isAdmin: false });
        }
    }, [employee]);

    const companyService = new CompanyAPI();


    const createOrUpdate = async (values, { resetForm }) => {
        const data = {
            ...values,
            isAdmin: isAdmin,
            isReception: isReception
        }
        setShowNotification(false)

        try {
            setIsLoading(true)
            if (employee) {
                await companyService.updateEmployee(companyService.getCompanyManagment().id, data);
            } else {
                await companyService.addEmployee(companyService.getCompanyManagment().id, data);
                setShowNotification(true)
                setTimeout(() => {
                    setShowNotification(false)
                }, 6000);
                resetForm();
            }
            HallAppToast.success();
            setIsLoading(false)

        } catch (e) {
            setIsLoading(false)
            HallAppToast.error();
        } finally {
            if (updateListEmployees) {
                updateListEmployees();
            }
        }
    }


    return (
        <>
            <Modal show={show} fullscreen={false} centered={true} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>{employee !== null ? 'Alterar' : 'Adicionar'} Funcionário</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        validationSchema={schema}
                        onSubmit={createOrUpdate}
                        enableReinitialize={true}
                        initialValues={initialValues}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>

                                <AppInput
                                    style={{ width: '100%' }}
                                    disabled={employee !== null}
                                    errors={errors}
                                    touched={touched}
                                    name='email'
                                    onChange={handleChange}
                                    value={values.email}
                                    type='email'
                                    label='E-mail' />

                                <p>Selecione a função:</p>
                                <Switch label="Administrador" checked={isAdmin} onChange={(e) => setAdmin(e.target.checked)} />
                                <Switch label="Funcionário" checked={true} onChange={(e) => console.log(e)} />
                                <Switch label="Recepção" checked={isReception} onChange={(e) => setReception(e.target.checked)} />


                                {isLoading ? <AppLoading /> :
                                    <AppButton type="submit" width='100%' name='adicionar' />
                                }
                            </Form>
                        )}
                    </Formik>

                    {
                        showNotification && <div style={{ marginTop: '20px' }}>
                            <Alert key={'warning'} variant={'warning'}>
                                Convite enviado com sucesso! Por favor, avise o funcionário que ele receberá um email para ativar a conta.
                            </Alert>
                        </div>
                    }

                </Modal.Body>
            </Modal>
        </>
    );
}

export default EmployeeModal;