import { useEffect, useState } from "react";

import AppLoading from "../../commons/AppLoading";
import HallAppCenterContainer from "../../commons/containers/HallAppCenterContainer";
import HomeFirstAccess from "./first-access/HomeFirstAccess";
import UserService from "../../auth/UserService";
import { selectedCompany } from "../../redux/actions/companyAction";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

function Home() {
  const [isLoading] = useState(false)
  const [isFirstAccess, setFirstAccess] = useState(true)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    chekCompany()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function chekCompany() {
    const companyManagement = localStorage.getItem('company-management')
    if (companyManagement === null || companyManagement === undefined) {
      setFirstAccess(true)
    } else {
      dispatch(selectedCompany(JSON.parse(companyManagement)))
      setFirstAccess(false)
    }
  }

  async function updateFirstAccess() {
    chekCompany()
  }

  const service = new UserService()

  function isReception() {
    const user = service.getUserData();
    return user.rules?.length !== 0 && user.rules?.includes("RECEPTION");
  }

  function redirectPage() {
    if (isFirstAccess) {
      return <HomeFirstAccess updateFirstAccess={updateFirstAccess} />
    } else {
      if (isReception()) {
        navigate("/comandas-recepcao");
      } else {
        navigate("/comandas");
      }

    }
  }


  return (
    <>
      {
        isLoading ? <HallAppCenterContainer><AppLoading /></HallAppCenterContainer> : <>
          {redirectPage()}
        </>
      }
    </>
  );
}

export default Home;