import { useEffect, useState } from 'react';

import CompanyAPI from '../../company/CompanyAPI';
import Form from 'react-bootstrap/Form';
import HallAppToast from '../../../commons/HallAppToast';
import ModalTime from './ModalTime';
import SchedulerConfigAPI from '../SchedulerConfigAPI';

function Weeks({ weekConfig }) {

    const [isChecked, setIsChecked] = useState(false);
    const [showModalTime, setShowModalTime] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const defaultStartTimeMorning = '09:00';
    const defaultEndTimeMorning = '12:00';

    const defaultStartTimeAfternoon = '13:00';
    const defaultEndTimeAfternoon = '18:00';
    const companyAPI = new CompanyAPI();
    const schedulerAPI = new SchedulerConfigAPI();

    const handleToggle = async (event) => {
        setIsChecked(event.target.checked);

        if (event.target.checked) {
            const dataCreate = {
                companyId: companyAPI.getCompanyManagment().id,
                dayOfWeek: weekConfig.dayOfWeek,
                endTimeAfternoon: defaultEndTimeAfternoon,
                endTimeMorning: defaultEndTimeMorning,
                startTimeAfternoon: defaultStartTimeAfternoon,
                startTimeMorning: defaultStartTimeMorning
            }
            try {
                setLoading(true)
                await schedulerAPI.configWeeklySchedule(dataCreate);
                HallAppToast.success();
            } catch (_) {
                HallAppToast.genericError();
            } finally {
                setLoading(false)
            }

        } else {
            try {
                setLoading(true)
                await schedulerAPI.deleteConfigWeeklySchedule(companyAPI.getCompanyManagment().id,weekConfig.dayOfWeek);
                HallAppToast.success();
            } catch (_) {
                HallAppToast.genericError();
            } finally {
                setLoading(false)
            }
        }
    };

    useEffect(() => {
        setIsChecked(weekConfig.isActive)
    }, []);

    function getWeekConfigText() {
        const { startTimeMorning, endTimeMorning, startTimeAfternoon, endTimeAfternoon } = weekConfig || {};

        if (startTimeMorning && endTimeMorning && startTimeAfternoon && endTimeAfternoon) {
            return `${startTimeMorning} á ${endTimeMorning} - ${startTimeAfternoon} á ${endTimeAfternoon}`;
        }

        if (startTimeMorning && endTimeMorning) {
            return `${startTimeMorning} á ${endTimeMorning}`;
        }

        if (startTimeAfternoon && endTimeAfternoon) {
            return `${startTimeAfternoon} á ${endTimeAfternoon}`;
        }

        return `${defaultStartTimeMorning} á ${defaultEndTimeMorning} - ${defaultStartTimeAfternoon} á ${defaultEndTimeAfternoon}`;
    }

    return (
        <>
            <div className="row d-flex justify-content-between align-items-center">
                <div className="col d-flex justify-content-start">
                    <Form.Check
                        disabled={isLoading}
                        checked={isChecked}
                        type="switch"
                        id={weekConfig.dayOfWeek}
                        label={weekConfig.description}
                        value={weekConfig.dayOfWeek}
                        onChange={handleToggle}
                    />
                </div>

                <div className="col-auto">
                    {isChecked && (
                        <span onClick={() => {
                            setShowModalTime(true)
                        }} style={{ fontSize: '13px' }}>
                            {getWeekConfigText()}
                        </span>
                    )}
                </div>
            </div>
            <ModalTime show={showModalTime}
                weekConfig={weekConfig}
                closeShow={() => setShowModalTime(false)} />
        </>
    );
}

export default Weeks;