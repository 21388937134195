import './Template.scss';

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Nav } from 'react-bootstrap';

interface StepProps {
    icon?: string;
    label: string;
    path: string;
    children?: StepProps[]; // Submenus
    handleClose?: () => void;
    isUserAdmin: Boolean;
}

const SidebarSubItem: React.FC<StepProps> = ({ label, path, children, handleClose, isUserAdmin }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false); // Para controlar o estado do submenu

    const redirect = () => {
        if (path) {
            navigate(path);
            handleClose?.();
        }
    };

    function getCurrentPath() {
        return location.pathname.replace("/", "");
    }

    const toggleSubmenu = () => setIsOpen(!isOpen);

    return (
        <div>
            <div
                style={{ backgroundColor: getCurrentPath() === path ? '#d4d2ff' : '' }}
                onClick={children ? toggleSubmenu : redirect} // Abre submenu ou redireciona
            >
                <Nav.Item>
                    <Nav.Link className="nav-link">
                        {/* <i style={{ color: 'red' }} className="bi bi-grid-fill sidebar-item-text"></i> */}
                        <span className="sidebar-item-text">{label}</span>
                        {children && (
                            <i
                                className={`bi ${isOpen ? 'bi-chevron-up' : 'bi-chevron-down'}`}
                                style={{ marginLeft: 'auto', cursor: 'pointer' }} // Ícone à direita com comportamento de colapse
                                onClick={(e) => {
                                    e.stopPropagation(); // Evita que o clique no ícone também dispare o evento de redirecionamento
                                    toggleSubmenu();
                                }}
                            ></i>
                        )}
                    </Nav.Link>
                </Nav.Item>
            </div>

            {isOpen && children && (
                <div className="submenu">
                    {children.filter((item) => {
                        if (item.validateAdmin && isUserAdmin) {
                            return true;
                        }

                        if (!item.validateAdmin) {
                            return true;
                        }
                    return false;
                    }).map((child, index) => (
                        <SidebarSubItem
                            key={index}
                            {...child}
                            handleClose={handleClose} // Propagate handleClose
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default SidebarSubItem;