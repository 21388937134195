import './Login.scss';

import * as formik from 'formik';
import * as yup from 'yup';

import React, { useEffect, useState } from 'react';

import AppButton from '../../commons/buttons/AppButton';
import AppInput from '../../commons/inputs/AppInput';
import AppLoading from '../../commons/AppLoading';
import AuthContainer from '../AuthContainer';
import CompanyAPI from '../../pages/company/CompanyAPI';
import { Form } from 'react-bootstrap';
import HallAppContainer from '../../commons/containers/HallAppContainer';
import HallAppToast from '../../commons/HallAppToast';
import Splash from '../../commons/pages/splash/Splash';
import { ToastContainer } from 'react-toastify';
import UserService from '../UserService';
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const [showSplash, setShowSplash] = useState(false)

    const service = new UserService();
    const companyAPI = new CompanyAPI();

    const navigate = useNavigate();

    const redirect = (path) => {
        navigate(path);
    };

    useEffect(() => {
        const cleanToken = () => {
            localStorage.removeItem('token')
            localStorage.removeItem('company-management')
            localStorage.removeItem('companies')
        };
        cleanToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isLoading, setIsLoading] = useState(false)
    const { Formik } = formik;

    const schema = yup.object().shape({
        email: yup.string().email('Email inválido').required('Email é obrigatório'),
        password: yup.string().required('Senha é obrigatória'),
    });

    const login = async (values) => {
        setIsLoading(true)
        service.login(values)
            .then(async (_) => {
                setShowSplash(true)
                const response = await companyAPI.findAllMe();
                if (response.data.length > 0) {
                    const data = response.data[0];
                    companyAPI.setCompanyManagment(data)
                    companyAPI.setCompaniesLocalStorage(response.data)
                }
                redirect('/inicio')
            }).catch((_) => {
                HallAppToast.error('E-mail ou Senha estão incorretos.');
            }).finally((_) => setIsLoading(false))
    };


    return (
        showSplash ? <Splash /> :
            <AuthContainer>
                <div className='login-content'>
                    {

                        <div>
                            <p style={{ fontSize: '20px', paddingTop: '30px' }}>Acesse sua conta do HallApp</p>
                            <Formik
                                validationSchema={schema}
                                onSubmit={login}
                                initialValues={{
                                    email: '',
                                    password: '',
                                }}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors }) => (
                                    <HallAppContainer>
                                        <Form  noValidate onSubmit={handleSubmit}>
                                            <AppInput
                                                errors={errors}
                                                touched={touched}
                                                name='email'
                                                onChange={handleChange}
                                                value={values.email}
                                                type='email'
                                                label='E-mail' />
                                            <AppInput
                                                errors={errors}
                                                touched={touched}
                                                name='password'
                                                onChange={handleChange}
                                                value={values.password}
                                                type='password'
                                                label='Senha' />

                                            <p className='login-password-recovery'>
                                                <span onClick={() => redirect('/recuperar-senha')} className='reset-password-txt'>
                                                    Esqueceu a senha?
                                                </span>
                                            </p>

                                            <div className='row login-btn-actions'>
                                                <div className='col'>
                                                    {isLoading ? <AppLoading /> :
                                                        <AppButton type="submit" width='100%' name='entrar' />
                                                    }
                                                </div>
                                            </div>

                                            <div style={{ marginTop: '50px' }}>
                                                <p style={{ fontSize: '18px' }}>Não tem conta? <span>
                                                    <span onClick={() => redirect('/criar-conta')} className='create-account-txt'>
                                                        Criar conta.
                                                    </span>
                                                </span></p>
                                            </div>
                                        </Form>
                                    </HallAppContainer>
                                )}
                            </Formik>
                        </div>

                    }
                    <ToastContainer />
                </div>
            </AuthContainer>
    );
};

export default Login;