import ApiService from "../../commons/services/ApiService";

const apiService = new ApiService();

export default class ServiceAPI {

    URI_V1 = "/v1/services"

    create = async (data: any) => {
        return await apiService.postFormData(`${this.URI_V1}`, data);
    };

    update = async (id: any, data: any) => {
        return await apiService.putFormData(`${this.URI_V1}/${id}`, data);
    };


    findAllByCompanyId = async (companyId: any) => {
        return await apiService.get(`${this.URI_V1}/company/${companyId}`);
    };

    deleteByIdAndCompanyId = async (serviceId: any) => {
        return await apiService.delete(`${this.URI_V1}/${serviceId}`);
    };

    findById = async (serviceId: any) => {
        return await apiService.get(`${this.URI_V1}/${serviceId}`);
    };
}
