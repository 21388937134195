import "./CardCommand.scss"

import * as formik from 'formik';
import * as yup from 'yup';

import { Col, Modal, Row } from "react-bootstrap";
import { displayName, formatDateInEN, formatInReal, getCurrentTimestamp } from "../../../commons/util/HallAppUtil";
import { useEffect, useState } from "react";

import AppButton from "../../../commons/buttons/AppButton";
import AppCurrencyInput from "../../../commons/inputs/AppCurrencyInput";
import AppInput from "../../../commons/inputs/AppInput";
import AppLoading from "../../../commons/AppLoading";
import AppTextAreaInput from "../../../commons/inputs/AppTextAreaInput";
import CalendarInput from "../../../commons/inputs/CalendarInput";
import CommandAPI from "../CommandAPI";
import CompanyAPI from "../../company/CompanyAPI";
import CustomContainer from "../../../commons/containers/CustomContainer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import HallAppCenterContainer from "../../../commons/containers/HallAppCenterContainer";
import HallAppToast from "../../../commons/HallAppToast";
import Select from 'react-select'
import ServiceAPI from "../../services/ServiceAPI";
import UserService from "../../../auth/UserService";
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const companyAPI = new CompanyAPI();
const serviceAPI = new ServiceAPI();
const userAPI = new UserService();
const commandAPI = new CommandAPI();


const customStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: 60,
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
};

function ModalCommand({ closeShow, show, findAllCommands, command }) {
    const [initialValues, setInitialValues] = useState({
        customer: '',
        description: ''
    });

    const [isLoading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [options, setOptions] = useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [serviceDate, setServiceDate] = useState(null);


    const [employees, setEmployees] = useState([])
    const [currentResponsible, setCurrentResponsible] = useState([])
    const [responsibles, setResponsibles] = useState([]);
    const [servicesValues, setServicesValues] = useState({});
    const [selectedServiceOptions, setSelectedServiceOptions] = useState([]);

    const { Formik } = formik;

    const schema = yup.object().shape({
        customer: yup.string().required('Informe o nome do(a) cliente').max(120, 'Nomde do cliente deve ter no maximo 120 cracteres'),
        description: yup.string()
    });


    useEffect(() => {

        if (command != null && Object.keys(command).length > 0) {
            setIsEdit(true)
            const dateString = command.serviceDate;
            const [year, month, day] = dateString.split("-");
            const localDate = new Date(year, month - 1, day); // Mês é 0-indexado
            setStartDate(localDate);

            setInitialValues({
                customer: command.customerName,
                description: command.description,
            })

            const key = getCurrentTimestamp();
            const selectedServices = []
            command.employees.forEach((e) => {
                const service = options.find((service) => service.value === e.serviceId)
                if (service) {
                    selectedServices.push({ ...service, key: `${key}_${service.value}_${e.employeeId}` })
                }
            })
            setSelectedServiceOptions(selectedServices);


            const responsibleAdded = [];
            employees.forEach((e) => {
                const commandEmployees = command.employees.filter((c) => c.employeeId === e.value)
                commandEmployees.forEach((commandEmployee) => {
                    if (commandEmployee !== null && commandEmployee !== undefined) {
                        const keyResp = `${key}_${commandEmployee.serviceId}_${commandEmployee.employeeId}`;
                        responsibleAdded[keyResp] = e
                        setServicesValues((prev) => ({
                            ...prev,
                            [keyResp]: commandEmployee.value,
                        }));
                    }
                })
            })
            setResponsibles(responsibleAdded)
        }
    }, [command, options, employees]);

    const create = async (values, { resetForm }) => {
        if (selectedServiceOptions.length === 0) {
            HallAppToast.error("Selecione ao menos um serviço realizado.");
            throw new Error("Serviços é obrigatório.");
        }

        const data = {
            customerName: values.customer,
            description: values.description,
            companyId: companyAPI.getCompanyManagment().id,
            serviceDate: serviceDate || formatDateInEN(new Date()),
            employees: getResponsibleData()
        }
        try {
            setLoading(true)
            if (command != null && Object.keys(command).length > 0) {
                await commandAPI.updateCommand(command.id, data);
                closeShow()
            } else {
                await commandAPI.createCommand(data);
                resetForm();
                resetStates();
            }
            HallAppToast.success();
            if (findAllCommands) {
                findAllCommands()
            }
        } catch (_) {
            HallAppToast.genericError();
        } finally {
            setLoading(false)
        }
    }

    function resetStates() {
        setStartDate(new Date())
        setResponsibles([])
        setSelectedServiceOptions([])
        setServicesValues([])
    }

    function getResponsibleData() {
        const responsiblesData = [];
        selectedServiceOptions.forEach((selectOption) => {
            const key = selectOption.key;
            const value = servicesValues[key];
            var responsible = {}

            try {
                responsible = responsibles[key] || currentResponsible;
            } catch (_) {
                responsible = currentResponsible;
            }

            const data = {
                employeeId: responsible.value,
                name: displayName(responsible.label),
                serviceId: selectOption.value,
                value: value || 0
            }
            responsiblesData.push(data)
        })
        return responsiblesData;
    }

    useEffect(() => {
        findAllServices();
        getAllEmployee();
    }, []);

    const findAllServices = async () => {
        try {
            setLoading(true)
            const response = await serviceAPI.findAllByCompanyId(companyAPI.getCompanyManagment().id)
            if (response.data) {
                response.data.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
                const options = response.data.map(d => ({ value: d.id, label: d.name }));
                setOptions(options)

            }
            setLoading(false)
        } catch (_) {
            setLoading(false)
        }
    };

    function changeResponsible(key, selected) {
        setResponsibles((prev) => ({
            ...prev,
            [key]: selected,
        }));
    }

    function changeServicesValue(key, value) {
        setServicesValues((prev) => ({
            ...prev,
            [key]: value,
        }));
    }

    function calculateTotalValue() {
        return Object.values(servicesValues).reduce((total, value) => total + value, 0);
    }

    const getAllEmployee = async () => {
        try {
            const response = await companyAPI.getAllEmployee(companyAPI.getCompanyManagment().id);
            const options = response.data.map(d => ({ value: d.id, label: displayName(d.name) || d.email, key: getCurrentTimestamp() }));
            setCurrentResponsible(options.find((e) => e.value === userAPI.getUserData().id));
            setEmployees(options)
        } catch (_) {
            setEmployees([])
        }
    };

    function handleChangeDate(date) {
        setServiceDate(date)
    }

    function handleChangeService(selected) {
        if (selected) {
            const newSelected = { ...selected, key: getCurrentTimestamp() };
            setSelectedServiceOptions([...selectedServiceOptions, newSelected]);
        }
    }

    function deleteServiceOption(keyToRemove) {
        if (keyToRemove) {
            removeServiceByKeyPart(keyToRemove)
            setSelectedServiceOptions(selectedServiceOptions.filter((value, _) => value.key !== keyToRemove));
        }
    }

    function removeServiceByKeyPart(keyToRemove) {
        if (servicesValues.hasOwnProperty(keyToRemove)) {
            delete servicesValues[keyToRemove];
            console.log(`Registro com chave ${keyToRemove} removido.`);
        } else {
            console.log(`Chave ${keyToRemove} não encontrada no objeto.`);
        }
    }

    return (
        <>
            <Modal className='hallapp-establishment-modal' show={show} fullscreen={true} onHide={() => closeShow()}>
                <Modal.Header className='hallapp-establishment-modal custom-close-button' closeButton>
                    <Modal.Title style={{ textAlign: 'center', fontSize: '15px' }}>
                        {isEdit ? 'ALTERAR COMANDA' : 'ADICIONAR COMANDA'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='hallapp-establishment-modal'>
                    <CustomContainer>
                        <Formik
                            validationSchema={schema}
                            onSubmit={create}
                            initialValues={initialValues}
                            enableReinitialize={true}
                        >
                            {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <AppInput errors={errors}
                                        touched={touched}
                                        name='customer'
                                        onChange={handleChange}
                                        value={values.customer}
                                        type='text'
                                        label='Nome do(a) cliente' />



                                    <div className="commando-form-service-date" style={{ marginBottom: '20px' }}>
                                        <CalendarInput
                                            selectionMode="single"
                                            setDates={setStartDate}
                                            changeDates={handleChangeDate}
                                            values={startDate} />
                                    </div>

                                    <div style={{ marginBottom: '20px' }}>
                                        <Select
                                            styles={customStyles}
                                            options={options}
                                            onChange={handleChangeService}
                                            isClearable
                                            isSearchable
                                            placeholder='Selecione os serviços'
                                        />
                                    </div>

                                    {selectedServiceOptions && selectedServiceOptions.map((option, index) => (
                                        <div key={index}>
                                            <Row key={index} >
                                                <Col xs={5} sm={5} md={5} lg={5}>
                                                    <AppCurrencyInput
                                                        key={index}
                                                        errors={errors}
                                                        touched={touched}
                                                        label={option.label}
                                                        value={servicesValues[option.key]}
                                                        onChange={(_, value) => changeServicesValue(option.key, value)} />
                                                </Col>
                                                <Col xs={5} sm={5} md={5} lg={5}>
                                                    <Select
                                                        menuPlacement="auto"
                                                        styles={customStyles}
                                                        options={employees}
                                                        placeholder='Responsável'
                                                        value={responsibles[option.key] || currentResponsible}
                                                        onChange={(e) => changeResponsible(option.key, e)}
                                                    />
                                                </Col>

                                                <Col xs={2} sm={2} md={2} lg={2}>
                                                    <div style={{ marginTop: '15px' }}>
                                                        <FontAwesomeIcon onClick={() => deleteServiceOption(option.key)} icon={faTrash} size="lg" color="red" />
                                                    </div>
                                                </Col>

                                            </Row>

                                        </div>
                                    ))}

                                    <AppTextAreaInput errors={errors}
                                        touched={touched}
                                        name='description'
                                        onChange={handleChange}
                                        value={values.description}
                                        type='text'
                                        label='Descrição' />

                                    <hr></hr>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>Total </span>
                                        </div>
                                        <div className="text-end">
                                            <span>{formatInReal(calculateTotalValue())}</span>
                                        </div>
                                    </div>
                                    <hr></hr>

                                    {isLoading ? <HallAppCenterContainer><AppLoading /> </HallAppCenterContainer> :
                                        <AppButton type="submit" width='100%' name='adicionar' />
                                    }
                                </Form>
                            )}
                        </Formik>
                    </CustomContainer>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalCommand;