import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { displayName, formatDate, formatDateTime, formatInReal, formatTransactionType } from "../../../commons/util/HallAppUtil";
import { useEffect, useState } from "react";

import Accordion from 'react-bootstrap/Accordion';
import AppButton from "../../../commons/buttons/AppButton";
import AppCurrencyInput from "../../../commons/inputs/AppCurrencyInput";
import AppInputSelect from "../../../commons/inputs/AppInputSelect";
import AppLoading from "../../../commons/AppLoading";
import CommandAPI from "../CommandAPI";
import ConfirmModal from "../../../commons/modal/ConfirmModal";
import CustomContainer from "../../../commons/containers/CustomContainer";
import HallAppCenterContainer from "../../../commons/containers/HallAppCenterContainer";
import HallAppToast from "../../../commons/HallAppToast";
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';

const options = [
    {
        value: 'CREDIT_CARD',
        label: 'Crédito'
    },
    {
        value: 'DEBIT_CARD',
        label: 'Debito'
    },
    {
        value: 'PIX',
        label: 'PIX'
    },
    {
        value: 'MONEY',
        label: 'Dinheiro'
    },
];

function CommandPaymentModal({ closeShow, show, command }) {
    const [type, setType] = useState(null);
    const [value, setValue] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [isLoadingTransactions, setLoadingTransaction] = useState(true);
    const [totalPaid, setTotalPaid] = useState(0);

    const [showModalDelete, setShowMoadlDelete] = useState(false);
    const [isLoadingModalDelete, setLoadingModalDelete] = useState(false);
    const [transaction, setTransaction] = useState({});

    const [hasChange, setHasChange] = useState(false);

    const commandAPI = new CommandAPI();

    useEffect(() => {
        getAllTransactions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    async function getAllTransactions() {
        try {
            setLoadingTransaction(true)
            const response = await commandAPI.getTransactions(command.id);
            setTransactions(response.data)
            if (response.data) {
                const total = response.data.reduce((sum, item) => sum + item.value, 0);
                setTotalPaid(total)
            }
            setLoadingTransaction(false)
        } catch (e) {
            HallAppToast.error("Não foi possivel carregar as transações, tente novamente.");
        }
    }

    async function create() {
        const data = {
            type: type || options[0].value,
            value: value,

        }
        if (value === null || value === undefined) {
            HallAppToast.error("Informe um valor");
            return;
        }

        try {
            setLoadingTransaction(true)
            await commandAPI.createTransaction(command.id, data);
            HallAppToast.success();
            setHasChange(true)
            getAllTransactions();
        } catch (e) {
            if (e?.response.status === 400) {
                if (e?.response?.data && e?.response?.data[0].code === 'COMMAND_VALUE') {
                    HallAppToast.error("O valor informado não é um valor valido, a soma total é maior que o valor total.");
                }
            }
        } finally {
            setLoadingTransaction(false)
        }
    }

    async function executeDeletion() {

        try {
            setLoadingModalDelete(true)
            await commandAPI.deleteTransaction(command.id, transaction.id)
            HallAppToast.success();
            setShowMoadlDelete(false)
            getAllTransactions();
            setHasChange(true)
        } catch (_) {
            HallAppToast.genericError();
        } finally {
            setLoadingModalDelete(false)
        }
    }

    function openModalDelete(transaction) {
        setTransaction(transaction)
        setShowMoadlDelete(true)
    }

    return (
        <>
            <Modal  className='hallapp-establishment-modal' show={show} fullscreen={true} onHide={() => closeShow(hasChange)}>
                <Modal.Header className='hallapp-establishment-modal custom-close-button' closeButton>
                    <Modal.Title style={{ textAlign: 'center', fontSize: '15px' }}>
                        PAGAMENTO
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='hallapp-establishment-modal'>
                    <CustomContainer>
                        <ListGroup>
                            <ListGroup.Item>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <strong>Cliente</strong>
                                    </div>
                                    <div>
                                        {command?.customerName}
                                    </div>
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <strong>Valor</strong>
                                    </div>
                                    <div>
                                        {formatInReal(command?.totalValue)}
                                    </div>
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <strong>Data</strong>
                                    </div>
                                    <div>
                                        {formatDate(command.serviceDate)}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                        <Table bordered style={{ marginTop: '20px' }}>
                            <thead>
                                <tr>
                                    <th>Profissional</th>
                                    <th>Serviço</th>
                                    <th>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {command.employees.map((e, index) => (
                                    <tr>
                                        <td>{displayName(e.employeeName || '')}</td>
                                        <td>{e.serviceName}</td>
                                        <td>{formatInReal(e.value)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <hr></hr>
                        <Table bordered style={{ marginTop: '20px' }}>
                            <thead>
                                <tr>
                                    <th>Valor Total</th>
                                    <th>Valor Pago</th>
                                    <th>Á Receber</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{formatInReal(command?.totalValue)}</td>
                                    <td>{formatInReal(totalPaid)}</td>
                                    <td>{formatInReal(command.totalValue - totalPaid)}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <hr></hr>
                        <Form>
                            <Row >
                                <Col xs={8} sm={6} md={6} lg={6}>
                                    <AppInputSelect
                                        options={options}
                                        label='Tipo de pagamento'
                                        onChange={(e) => setType(e.target.value)}
                                    />
                                </Col>
                                <Col xs={4} sm={6} md={6} lg={6}>
                                    <AppCurrencyInput
                                        label='Valor'
                                        onChange={(_, value) => setValue(value)} />
                                </Col>
                            </Row>

                            <AppButton disabled={isLoadingTransactions} onClick={create} width='100%' name='adicionar' />
                        </Form>
                        <hr></hr>
                        {
                            isLoadingTransactions ? <HallAppCenterContainer><AppLoading /></HallAppCenterContainer>
                                : <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Historico de Pagamentos</Accordion.Header>
                                        <Accordion.Body>
                                            {
                                                transactions.map((e) => (
                                                    <ListGroup>
                                                        <ListGroup.Item>
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <strong>Resp. pagamento</strong>
                                                                </div>
                                                                <div>
                                                                    {displayName(e?.responsibleCreate || '')}
                                                                </div>
                                                            </div>
                                                        </ListGroup.Item>
                                                        <ListGroup.Item>
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <strong>Tipo</strong>
                                                                </div>
                                                                <div>
                                                                    {formatTransactionType(e.type)}
                                                                </div>
                                                            </div>
                                                        </ListGroup.Item>
                                                        <ListGroup.Item>
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <strong>Valor</strong>
                                                                </div>
                                                                <div>
                                                                    {formatInReal(e.value)}
                                                                </div>
                                                            </div>
                                                        </ListGroup.Item>
                                                        <ListGroup.Item>
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <strong>Data</strong>
                                                                </div>
                                                                <div>
                                                                    {formatDateTime(e.createDate)}
                                                                </div>
                                                            </div>
                                                        </ListGroup.Item>
                                                        <ListGroup.Item>
                                                            <Button style={{ width: '100%' }}
                                                                onClick={() => openModalDelete(e)}
                                                                variant="outline-danger">EXCLUIR</Button>
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                ))
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                        }

                    </CustomContainer>
                    <ConfirmModal show={showModalDelete}
                        title='Excluir'
                        isLoading={isLoadingModalDelete}
                        confirm={executeDeletion}
                        closeModal={() => {
                            setShowMoadlDelete(false)
                            setTransaction({})
                        }}
                    >
                        Deseja excluir o valor pago no dia {formatDateTime(transaction.createDate)} de {formatInReal(transaction.value)}?
                    </ConfirmModal>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CommandPaymentModal;