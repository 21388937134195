import './Input.scss';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import React from 'react';

const AppInput = ({ type = 'text', label, value, onChange, name, errors, touched, mask, formText, disabled = false, readOnly = false, onClick,
    backgroundColor
}) => {
    const inputClassName = `custom-input-focus custom-input ${disabled ? 'input-disabled' : ''}`;

    return (
        <>
            <FloatingLabel
                controlId={name}
                label={label}
                className="mb-3 custom-input-label"
            >
                <Form.Control name={name}
                    style={{ backgroundColor: backgroundColor }}
                    onClick={onClick}
                    onChange={onChange}
                    readOnly={readOnly}
                    value={value}
                    className={inputClassName}
                    type={type}
                    ref={mask}
                    disabled={disabled}
                    isInvalid={!!errors[name] && touched} />
                <Form.Control.Feedback style={{ textAlign: 'left' }} type="invalid">
                    {errors[name]}
                </Form.Control.Feedback>
                <Form.Text id="passwordHelpBlock" muted>
                    <p>{formText}</p>
                </Form.Text>
            </FloatingLabel>
        </>
    );
};

export default AppInput;