import axios, { AxiosHeaders, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import EnvLocal from '../../env.local';

const baseURL = EnvLocal.REACT_APP_API_URL;

class ApiService {
  private axiosInstance: AxiosInstance;

  constructor(config?: AxiosRequestConfig) {
    this.axiosInstance = axios.create({
      baseURL,
      ...config,
    });
    this.axiosInstance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token');
        if (token) {
          if (config.headers) {
            const headers = new AxiosHeaders(config.headers);
            headers.set('Authorization', `${token}`);
            config.headers = headers;
          } else {
            config.headers = new AxiosHeaders({
              Authorization: `Bearer ${token}`,
            });
          }
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

  }

  // Método GET
  async get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.get<T>(url, config);
  }

  // Método POST
  async post<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.post<T>(url, data, config);
  }

  async postFormData<T>(url: string, data: FormData, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const formDataHeaders = {
      'Content-Type': 'multipart/form-data',
      ...(config?.headers || {}),
    };

    return this.axiosInstance.post<T>(url, data, {
      ...config,
      headers: formDataHeaders,
    });
  }

  async putFormData<T>(url: string, data: FormData, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const formDataHeaders = {
      'Content-Type': 'multipart/form-data',
      ...(config?.headers || {}),
    };

    return this.axiosInstance.put<T>(url, data, {
      ...config,
      headers: formDataHeaders,
    });
  }

  // Método PUT
  async put<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.put<T>(url, data, config);
  }

  // Método DELETE
  async delete<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.delete<T>(url, config);
  }

  // Método PATCH
  async patch<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.patch<T>(url, data, config);
  }
}

export default ApiService;